import { format } from 'date-fns'
import { Link } from 'gatsby'
import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import { EyeBrowText, Headline, HeroHeadline } from '../components/atoms'
import { Layout } from '../components/layouts/Layout'
import {
  ConstrainedWidthContainer,
  GradientHeroContainer,
  MediaCard,
} from '../components/molecules'
import { GetInTouch, SEO, ShareButtonAndModal } from '../components/organisms'
import DotsSvg from '../images/dots.svg'
import { Mq } from '../styles/settings/theme'
import styled from '../styles/styled'

const HeroMobileImage = styled(Box)`
  background: no-repeat center url("${props => props.imageUrl}");
  background-size: cover;
  padding: 1rem;
  margin-bottom: 2rem;
  min-height: 13rem;

  ${Mq.sm} {
    display: none;
  }
`

const ContentContainer = styled(Box)`
  background-color: ${props => props.theme.colors.lightBlue};
`

const PanelsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem 1rem;
  padding-top: 3rem;

  ${Mq.sm} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem 1rem;
  }
`

const StyledEyeBrowText = styled(EyeBrowText)`
  ${Mq.sm} {
    color: ${props => props.theme.colors.white};
  }
`

const StyledShareButtonAndModal = styled(ShareButtonAndModal)`
  width: 100%;

  ${Mq.sm} {
    width: auto;
    position: sticky;
    top: 2rem;
  }
`

const Dots = styled(Box)`
  background-image: url('${DotsSvg}');
  position: absolute;
  width: 12rem;
  height: 21rem;
  bottom: 12rem;
  left: -7rem;
  z-index: -1;
  display: none;

  ${Mq.xl} {
    display: block;
  }
`

const EmbedContainer = styled(Box)`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const MediaDetail = ({ pageContext: { page, relatedPages, template } }) => {
  return (
    <Layout>
      <SEO
        title={page.title}
        description={page.short_description}
        imageSrc={page.hero_image.url}
      />

      <Box mb={[3, 0]}>
        <GradientHeroContainer imageUrl={page.hero_image.url}>
          <ConstrainedWidthContainer width={[1]}>
            <HeroMobileImage imageUrl={page.hero_image.url} />
            <Box mb={3}>
              <StyledEyeBrowText>
                {page.category[0].title} - Published on{' '}
                {format(new Date(page.publish_date), 'MMMM dd, yyyy')}
              </StyledEyeBrowText>
            </Box>

            <Box width={[1, 1, 1, 1, 11 / 12, 9 / 12]}>
              <HeroHeadline>{page.title}</HeroHeadline>
            </Box>
          </ConstrainedWidthContainer>
        </GradientHeroContainer>
      </Box>

      <ContentContainer width={[1]}>
        <ConstrainedWidthContainer py={[3, 3, 3, 6]}>
          <Flex flexWrap="wrap">
            <Box my={[4, 4, 4, 0]} width={[1, 1, 1, 3 / 12]}>
              <StyledShareButtonAndModal subjectTitle={page.title} />
            </Box>
            <Box width={[1, 1, 1, 8 / 12, 6 / 12]}>
              {page.embed_code ? (
                <EmbedContainer
                  dangerouslySetInnerHTML={{
                    __html: page.embed_code,
                  }}
                  mb={[3, 4]}
                />
              ) : null}

              <div
                dangerouslySetInnerHTML={{
                  __html: page.content,
                }}
              />
            </Box>
          </Flex>
        </ConstrainedWidthContainer>
      </ContentContainer>

      <ConstrainedWidthContainer py={[3, 3, 3, 6]}>
        <Headline>{template.other_articles_heading}</Headline>
        <Dots />
        <PanelsContainer>
          {relatedPages.map(relatedPage => (
            <Link key={relatedPage.url} to={relatedPage.url}>
              <MediaCard
                title={relatedPage.title}
                date={new Date(relatedPage.publish_date)}
                imageUrl={relatedPage.hero_image.url}
              />
            </Link>
          ))}
        </PanelsContainer>
      </ConstrainedWidthContainer>

      <GetInTouch />
    </Layout>
  )
}

export default MediaDetail
